import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import SelectLanguage from "./selectLanguage";
import axios from "axios";
import CustomTooltip from "../Tooltip";
import clsx from "clsx";
import { MenuRounded } from "@mui/icons-material";
import Drawer from "./drawer";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "white",
    boxShadow: "0px 10px 30px -10px #AAA",
    // height: '90px !important',
    // minHeight: "100px",
    maxHeight: "100px",
    padding: "8px 10px",
    zIndex: "1001",
    position: "fixed",
    [theme.breakpoints.down("md")]: {
      height: "80px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "50px !important",
    },
    // border: "10px solid red"
  },
  toolbar: {
    minHeight: "unset",
    height: "90px !important",
    [theme.breakpoints.down("md")]: {
      height: "80px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "50px !important",
    },
  },
  logosWrapperBox: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "80px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "50px !important",
    },
  },
  pointofview: {
    cursor: "pointer",
  },
  upperGovtLogo: {
    height: 70,
    [theme.breakpoints.down("md")]: {
      height: "70px !important",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "60px !important",
    },
    [theme.breakpoints.down("xsm")]: {
      height: "40px !important",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Drawer open={open} setOpen={setOpen} />
      <AppBar position="sticky" elevation={10} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logosWrapperBox}>
            <img
              src={require("./assets/govtofindia.png")}
              alt="govtofindia"
              className={classes.upperGovtLogo}
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {location.pathname !== "/" && window.innerWidth > 700 && (
                <SelectLanguage />
              )}
              {/* <img
                src={require("./assets/azadikamahotsav.png")}
                alt=""
                className={classes.upperGovtLogo}
              /> */}
              <img
                src={require("./assets/g20.png")}
                alt=""
                className={classes.upperGovtLogo}
              />
              {/* {(location.pathname === "/" &&
                localStorage.getItem("ModLanguage") === "en") ||
              (location.pathname === "/" &&
                localStorage.getItem("ModLanguage") === "hi") ? (
                <img
                  src={require("./assets/MOD_hindi_logo.png")}
                  onClick={() => {
                    navigate("/");
                  }}
                  alt=""
                  className={clsx(classes.upperGovtLogo, classes.pointofview)}
                />
              ) 
              : localStorage.getItem("ModLanguage") === "hi" ? (
                <img
                  src={require("./assets/MOD_hindi_logo.png")}
                  onClick={() => {
                    navigate("/");
                  }}
                  alt=""
                  className={clsx(classes.upperGovtLogo, classes.pointofview)}
                />
              ) 
              : (
                <img
                  src={require("./assets/MOD_new_logo.png")}
                  onClick={() => {
                    navigate("/");
                  }}
                  alt=""
                  className={clsx(classes.upperGovtLogo, classes.pointofview)}
                />
              )} */}
              <img
                src={require("./assets/MOD_new_logo.png")}
                onClick={() => {
                  navigate("/");
                }}
                alt=""
                className={clsx(classes.upperGovtLogo, classes.pointofview)}
              />
              {/* {window.innerWidth < 700 && <IconButton onClick={() => {

              }} >
                <MenuRounded />
                </IconButton>} */}
              {location.pathname !== "/" && window.innerWidth < 700 && (
                <IconButton onClick={() => setOpen(true)}>
                  <MenuRounded
                    sx={{ color: "#4e342c", height: "35px", width: "35px" }}
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
