import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import SelectLanguage from './selectLanguage'

export default function TemporaryDrawer(props) {
  const { open, setOpen, list: data, gate, dataa } = props;
  const navigate = useNavigate();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <List>
        <ListItem key={1} disablePadding>
          <ListItemText
            primary={
              <Box sx={{display: 'flex',marginLeft:'15px', marginBottom: '30px'}}>
                <SelectLanguage />
              </Box>
            }
          />
        </ListItem>
      </List>
    </Box>
  );

  const anchor = "right";

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer anchor={anchor} open={open} onClose={() => setOpen(false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
