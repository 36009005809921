import React from "react";
import { Routes, Route } from "react-router-dom";

const Home = React.lazy(() => import("./views/Home"));
const AncientRoots = React.lazy(() => import("./views/AncientRoots"));

const Contents = React.lazy(() => import("./views/Contents"));
const ContentParticulars = React.lazy(() =>
  import("./views/ContentParticulars")
);
const PMQoutes = React.lazy(() => import("./views/PMQuote"));
const LegacyOfDemocracy = React.lazy(() => import("./views/LegacyOfDemocracy"));

export const routes = [
  { path: "/", element: Home },
  {
    path: "/contents/*",
    element: ContentParticulars,
  },
  { path: "/ancient-roots", element: AncientRoots },
  { path: "/primeMinister-quote", element: PMQoutes },
  { path: "/legacy-of-democracy", element: LegacyOfDemocracy },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <React.Suspense fallback={<>...</>}>
                <route.element />
              </React.Suspense>
            }
          >
            <Route path={route.param} element={<route.paramElement />} />
          </Route>
        );
      })}
    </Routes>
  );
};
