import Header from "./components/Header";
import makeStyles from "@mui/styles/makeStyles";
import { Box, ButtonBase, Paper } from "@mui/material";
import clsx from "clsx";
import { AppRoutes } from "./routes";
import "./i18n";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "100px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0 0 0",
    },
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={clsx("App", classes.root)}>
      <Header />
      <AppRoutes />
    </div>
  );
}

export default App;
